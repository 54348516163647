import React from "react";

import ChipButton from "./ChipButton";
import { supportData } from "./constants";

import "./styles.scss";

const Chip = () =>
  (
    <div className="main-page__item --small-indent request__container">
      <div className="page-width">
        <div className="request">
          <div className="request__inner">
            <h3 className="h2 request__title">{supportData.title}</h3>
            <div className="request__socials">
              {supportData.chips.map(({
                title, link, icon, color,
              }) =>
                (
                  <ChipButton key={title} title={title} icon={icon} link={link} color={color} />
                ))}
            </div>
            <p className="request__footer">{supportData.schedule}</p>
          </div>
        </div>
      </div>
    </div>
  );

export default Chip;
