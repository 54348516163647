import React from 'react'
import "./styles.scss"

const VideoStorys = () => {
  return (
    <div className="video-storys">
      <div className="getreview-widget" data-widget-id="BImuS5UGnJQs0xlK"></div>
    </div>
  )
}

export default VideoStorys
