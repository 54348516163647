import * as React from "react";

import arrowSmall from "@images/main/arrow-small.svg";
import arrow from "@images/main/arrow.svg";
import starBig from "@images/main/star_big.svg";
import star from "@images/main/star_small.svg";
import { Link } from "gatsby";

import useOnboardingForClient from "@/hooks/useOnboardingForClient";
import { useMarketingHook } from "@/marketing/marketingHook";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { CATALOG_PAGE_NAME } from "@components/constants";

import VideoComponent from "../videoComponet/VideoComponent";

import images from "./constants";

import "./styles.scss";

const HowIsConsultation = () => {
  const isFFAllowFirstVideoSessionWithoutPaymentOn = process.env.GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT === "true";
  const { isActiveOnboarding, openOnboardingModal, linkForOpenOnboarding } = useOnboardingForClient();
  const { marketingMainPage3, marketingOnboardingAnswers } = useMarketingHook();

  return (
    <div className="main-page__item how-is-consultation">
      <div className="page-width how-is-consultation__inner">
        <h2 className="h1 main-page__title how-is-consultation__title">
          Как проходит консультация
          <br />
          на Lunaro
        </h2>
        <div className="how-is-consultation__items">
          {images.map((item, idx) =>
            (
              <img
                key={idx}
                src={item.default}
                srcSet={item.srcSet}
                alt={item.alt}
                className={item.className}
                loading="lazy"
              />
            ))}
          <div className="how-is-consultation__item">
            <p>
              Выбирайте удобный формат
              {" "}
              <span>видео, чат или по&nbsp;телефону</span>
              {" "}
              прямо на&nbsp;нашем сайте.
            </p>
            <p>Скачивать программы и&nbsp;переходить в&nbsp;мессенджеры не&nbsp;нужно</p>
          </div>
          <div className="how-is-consultation__item">
            <img src={starBig} alt="" />
            <p>
              <span>Можно отключить камеру</span>
              {" "}
              перед видеозвонком, если не&nbsp;хотите, чтобы
              эксперт вас видел.
            </p>
            <p>Вы увидите эксперта и&nbsp;карты, а&nbsp;ему будет достаточно вас слышать</p>
          </div>
          <div className="how-is-consultation__item">
            <p>
              <span>
                {isFFAllowFirstVideoSessionWithoutPaymentOn
                  ? `Первая консультация по\u00a0видео бесплатно`
                  : `Дарим 7\u00a0минут`}
              </span>
              {" "}
              {isFFAllowFirstVideoSessionWithoutPaymentOn
                ? `и\u00a0длится 7\u00a0минут.`
                : `при\u00a0первом пополнении баланса.`}
            </p>
            {isFFAllowFirstVideoSessionWithoutPaymentOn
              && <p>Этого времени хватит, чтобы разобрать один простой вопрос.</p>}
            <p>
              {isFFAllowFirstVideoSessionWithoutPaymentOn
                ? `Привязывать карту не\u00a0нужно`
                : `Этого времени хватит, чтобы разобрать один простой вопрос`}
            </p>
          </div>
          <div className="how-is-consultation__item">
            <p>
              Консультации оплачиваются поминутно.
            </p>
            <p>
              <span>Стоимость&nbsp;—&nbsp;от&nbsp;70₽/мин.</span>
            </p>
            <p>Общайтесь с&nbsp;экспертом в&nbsp;чате, по&nbsp;видео или&nbsp;телефону, сколько захотите</p>
          </div>
        </div>
        <div className="how-is-consultation__video-container">
          <div className="how-is-consultation__video">
            <div className="how-is-consultation__video-text">
              Блогер Tim Bites рассказал,
              {" "}
              <br />
              как прошла его
              {" "}
              <br />
              1-я консультация
              {" "}
              <br />
              на Lunaro
              <img
                src={arrow}
                alt=""
                className="how-is-consultation__video-arrow --normal"
                loading="lazy"
              />
              <img
                src={arrowSmall}
                alt=""
                className="how-is-consultation__video-arrow --small"
                loading="lazy"
              />
            </div>
            <img src={star} alt="" className="how-is-consultation__video-star" loading="lazy" />
            <img
              src={star}
              alt=""
              className="how-is-consultation__video-star --second"
              loading="lazy"
            />
            <VideoComponent />
          </div>
        </div>
        <div className="how-is-consultation__btn">
          <Link
            onClick={() => {
              marketingMainPage3();
              if (isActiveOnboarding) {
                marketingOnboardingAnswers("step-0", "The onboarding modal was opened");
              }
            }}
            to={isActiveOnboarding ? linkForOpenOnboarding : `/${CATALOG_PAGE_NAME}`}
          >
            <Button
              text={isActiveOnboarding ? "Подобрать таролога" : "Посмотреть экспертов"}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HowIsConsultation;
