import icon1 from "@images/main/howis1.svg";
import icon2 from "@images/main/howis2.svg";
import icon3 from "@images/main/howis3.svg";

import { CardBlockItem } from "@/components/common/cardBlock/types";

export const CONSULTATION_INFO_DATA: { header: string, items: CardBlockItem[] } = {
  header: `Как проходит\nТаро-консультация`,
  items: [
    {
      id: "1",
      description: `<h3>Ваш запрос</h3><p>Эксперт выслушает вас\u00a0и поможет сформулировать вопрос</p>`,
      icon: icon1,
    },
    {
      id: "2",
      description: `<h3>Таро-расклад</h3><p>Эксперт выберет расклад под ваш\u00a0вопрос, выложит карты и\u00a0покажет их</p>`,
      icon: icon2,
    },
    {
      id: "3",
      description: `<h3>Трактовка карт</h3><p>Эксперт расшифрует значения карт, ответит на\u00a0ваши вопросы и\u00a0расскажет, что произойдёт</p>`,
      icon: icon3,
    },
  ],
};
