import React from "react";

import { navigate } from "gatsby";

import { CATALOG_PAGE_NAME } from "@/components/constants";
import { declenateWord } from "@/utils/stringUtils";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import { UserAvatar } from "@components/common/userAvatar";
import { UserAvatarTypeEnum } from "@components/common/userAvatar/types";
import UserInfo from "@components/common/userInfo";
import TextItem from "@components/main/Stories/TextItem";
import { StoryItemProps } from "@components/main/Stories/types";

const Item = (props: { data: StoryItemProps }) => {
  const {
    user, expert, request, consultation, result,
  } = props?.data as StoryItemProps;

  return (
    <div className="story__inner">
      <div className="story__top">
        <UserInfo
          className="user-info--small"
          avatar={(
            <UserAvatar
              mobileImage={user.mobileImage}
              desktopImage={user.desktopImage}
              name={user.name}
              userId={1}
              type={UserAvatarTypeEnum.Stories}
            />
          )}
          title={user.name}
          subtitle={user.subtitle}
        />
        <div className="story__result">{user.result}</div>
        <TextItem title={request.title} content={request.content} />
      </div>
      <div className="story__middle">
        <div className="story__expert">
          <div className="expert-showcase">
            <div className="expert-showcase__inner">
              <picture>
                <source
                  srcSet={`${expert.desktopImage.onex}.jpg,
                      ${expert.desktopImage.twox}@2x.jpg 2x`}
                />
                <source
                  srcSet={`${expert.desktopImage.twox}.jpg,
                      ${expert.desktopImage.twox}@2x.jpg 2x`}
                />
                <img
                  src={`${expert.desktopImage.twox}@2x.jpg`}
                  alt=""
                  className="expert-showcase__img"
                  width="550"
                  height="550"
                />
              </picture>
            </div>
            <div className="expert-showcase__info-container">
              <div className="expert-showcase__info">
                Консультировала
                <br />
                эксперт
              </div>
              <span
                className="expert-showcase__name"
                onClick={() =>
                  navigate(expert.url || `/${CATALOG_PAGE_NAME}`)}
              >
                {expert.name}
              </span>
              <img src="/main/story-arrow.svg" width="45" height="40" alt="" />
            </div>
          </div>
        </div>
        <div className="story__info">
          <div className="story__info-item">
            <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size24} />
            {user.sessionDuration}
            {declenateWord(user.sessionDuration!, ["минута", "минуты", "минут"])}
          </div>
          <div className="story__info-item">
            <Icon type={IconTypeEnum.Payment} size={IconSizeEnum.Size24} />
            {user.price}
            ₽
          </div>
        </div>
      </div>
      <div className="story__bottom">
        <TextItem title={consultation.title} content={consultation.content} />
        <TextItem title={result.title} content={result.content} />
      </div>
    </div>
  );
};
export default Item;
