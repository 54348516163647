export default [
  {
    default: "/main/card_01.png",
    srcSet: "/main/card_01.png, /main/card_01@2x.png 2x, /main/card_01@3x.png 3x",
    alt: "",
    className: "how-is-consultation__image how-is-consultation__image--1",
  },
  {
    default: "/main/card_02.png",
    srcSet: "/main/card_02.png, /main/card_02@2x.png 2x, /main/card_02@3x.png 3x",
    alt: "",
    className: "how-is-consultation__image how-is-consultation__image--2",
  },
  {
    default: "/main/card_03.png",
    srcSet: "/main/card_03.png, /main/card_03@2x.png 2x, /main/card_03@3x.png 3x",
    alt: "",
    className: "how-is-consultation__image how-is-consultation__image--3",
  },
  {
    default: "/main/card_04.png",
    srcSet: "/main/card_04.png, /main/card_04@2x.png 2x, /main/card_04@3x.png 3x",
    alt: "",
    className: "how-is-consultation__image how-is-consultation__image--4",
  },
];
