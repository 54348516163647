import React from "react";

import { TextItemProps } from "@components/main/Stories/types";

const TextItem = (props: TextItemProps) => {
  const { title, content } = props;

  return (
    <div className="story__text">
      <div className="story__text-title">{title}</div>
      {content.map((elem, index) =>
        <p key={index} dangerouslySetInnerHTML={{ __html: elem }} />)}
    </div>
  );
};

export default TextItem;
