import React, { useState } from "react";

const VideoComponent = () => {
  const [showThirdPartyProduct, setShowThirdPartyProduct] = useState(false);

  const handleMouseOver = () => {
    setShowThirdPartyProduct(true);
  };

  const handleClick = () => {
    setShowThirdPartyProduct(true);
  };

  if (showThirdPartyProduct) {
    return (
      <div className="responsive-video-16-9">
        <iframe
          src="https://www.youtube.com/embed/5dteIGw-WW8?modestbranding=1&rel=0&showinfo=0&disablekb=1&iv_load_policy=3"
          title="Tim Bites about Lunaro - YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    );
  }

  return (
    <>
      <div className="image-youtub" onMouseOver={handleMouseOver} onClick={handleClick}>
        <img src="main/youtube_face_image.jpg" width="550" height="400" alt="Facade" />
      </div>
    </>
  );
};

export default VideoComponent;
