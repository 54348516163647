import * as React from "react";

import BulletBlock from "@/components/common/bulletBlock";
import ShowcaseBlock from "@/components/common/showcaseBlock";
import { CATALOG_PAGE_NAME } from "@components/constants";

import {
  MAIN_PAGE_SHOWCASE_CAROUSEL_ITEMS,
  MAIN_PAGE_SHOWCASE_EXPERT_DATA,
  MAIN_PAGE_SHOWCASE_IMAGE_DATA,
  MAIN_PAGE_SHOWCASE_LABELS,
  MAIN_PAGE_SHOWCASE_SLANTED_LABELS,
  PRIVILEGES,
} from "./constants";

import "./styles.scss";

const Showcase = () =>
(
  <div className="showcase">
    <div className="page-width">
      <ShowcaseBlock
        title={`Онлайн-консультации с\u00a0проверенными тарологами`}
        carouselItems={MAIN_PAGE_SHOWCASE_CAROUSEL_ITEMS}
        buttonText="Посмотреть экспертов"
        buttonLink={`/${CATALOG_PAGE_NAME}`}
        labels={MAIN_PAGE_SHOWCASE_LABELS}
        slantedlabels={MAIN_PAGE_SHOWCASE_SLANTED_LABELS}
        expertData={MAIN_PAGE_SHOWCASE_EXPERT_DATA}
        imageData={MAIN_PAGE_SHOWCASE_IMAGE_DATA}
      />
      <BulletBlock items={PRIVILEGES} />
    </div>
  </div>
);

export default Showcase;
