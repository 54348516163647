import * as React from "react";

import LogoSrc from "@images/logo.svg";

import { Icon, IconSizeEnum } from "@components/common/icon";

import { socialLinks } from "./constants";
import "./styles.scss";

const SocialNetworkBlock = () =>
  (
    <div className="main-page__item --small-indent subscribe">
      <div className="page-width">
        <div className="subscribe__inner">
          <div className="subscribe__bg">
            <picture>
              <source
                srcSet={`/main/subscribe-bg.webp,
                      /main/subscribe-bg@2x.webp 2x,
                      /main/subscribe-bg@3x.webp 3x`}
              />
              <source
                srcSet={`/main/subscribe-bg.jpg,
                      /main/subscribe-bg@2x.png 2x,
                      /main/subscribe-bg@3x.png 3x`}
              />
              <img src="/main/instruction@2x.jpg" alt="" width="1146" height="288" />
            </picture>
          </div>
          <img src={LogoSrc} alt="logo" className="subscribe__logo" />
          <h3 className="h2 subscribe__title">Подписывайтесь на наши соцсети</h3>
          <div className="subscribe__subtitle">
            Вас ждут полезные рубрики по Таро, истории наших клиентов,
            {" "}
            <br />
            послания дня и многое другое
          </div>
          <div className="subscribe__social">
            {socialLinks.map((social) =>
              (
                <a
                  className={`subscribe__social-item subscribe__social-item--${social.title.toLowerCase()}`}
                  href={social.link}
                  key={social.title}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={social.ariaLabel}
                >
                  <Icon type={social.title} size={IconSizeEnum.Size64} />
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

export default SocialNetworkBlock;
