import { StepBlockItem } from "@/components/common/stepBlock/types";

const isFFAllowFirstVideoSessionWithoutPaymentOn = process.env.GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT === "true";

export const CONSULTATION_STEP_INFO: { header: string, items: StepBlockItem[] } = {
  header: isFFAllowFirstVideoSessionWithoutPaymentOn
    ? "Попробуйте бесплатную консультацию"
    : "Как получить\n7 минут в подарок",
  items: [
    {
      id: "1",
      title: "1. Выберите таролога в\u00a0каталоге",
      description: "На нашем сервисе более 20 проверенных экспертов. Мы проводим тщательный отбор, чтобы консультации были полезными и\u00a0без воды",
    }, {
      id: "2",
      title: isFFAllowFirstVideoSessionWithoutPaymentOn
        ? "2. Зарегистрируйтесь и\u00a0заполните анкету"
        : "2. Создайте аккаунт и\u00a0пополните баланс от\u00a099\u00a0₽",
      description: "Нужно для безопасности вашего аккаунта. И вы экономите время: эксперт сразу перейдёт к разбору вопроса",
    }, {
      id: "3",
      title: "3. Проверьте связь",
      description: "Разрешите доступ к микрофону и камере. Перед звонком можно выключить камеру, если не хотите, чтобы эксперт вас видел",
    }, {
      id: "4",
      title: "4. Начните консультацию",
      description: "Переходите к\u00a0консультации и\u00a0знакомьтесь со\u00a0своим экспертом",
    },
  ],
};
