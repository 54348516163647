import { EXPERT_RATE_FOR_TEXTS } from "@/constants";

import { StoryItemProps } from "./types";

export const MAIN_PAGE_STORY_ITEMS: StoryItemProps[] = [
  {
    user: {
      mobileImage: {
        onex: "/main/user1.jpg",
        twox: "/main/user1.jpg",
        threex: "/main/user1.jpg",
      },
      desktopImage: {
        onex: "/main/user1.jpg",
        twox: "/main/user1.jpg",
        threex: "/main/user1.jpg",
      },
      name: "Мария",
      subtitle: "Преподаватель \u000Aанглийского",
      sessionDuration: 18,
      price: 18 * EXPERT_RATE_FOR_TEXTS,
      result: "Убедилась в своём решении расстаться, нашла силы идти дальше",
    },
    expert: {
      id: 21,
      mobileImage: {
        onex: "/main/new_expert_02",
        twox: "/main/new_expert_02",
        threex: "/main/new_expert_02",
      },
      desktopImage: {
        onex: "/main/new_expert_02",
        twox: "/main/new_expert_02",
        threex: "/main/new_expert_02",
      },
      name: "Ружена",
      isFired: false,
      url: "/expert/29",
    },
    request: {
      title: `Запрос`,
      content: [`Правильным ли было моё решение разорвать длительные отношения?`],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `Сама консультация прошла очень спокойно. Это мне и нужно было. <span>Таролог внимательно выслушала мою проблему, предложила вариант расклада.</span> Я не очень хорошо разбираюсь в самих картах и таролог объяснила мне всё подробно: как и что она будет делать, что будет означать каждая карта в раскладе.`,
        `Таролог рассказала мне, какие последствия будут от принятого мной решения. <span>Меня поразило, что каждая карта настолько точно описывала всю ситуацию!</span> В раскладе говорилось про то, что я испытываю сомнения и у меня есть мысли простить бывшего, потому что столько всего хорошего было. Но таролог бережно объяснила мне, что несмотря на всё это — решение расстаться было правильным. Сказала, что я на правильном пути. Это мне и нужно было: ощутить, что я всё делаю верно, получить обоснованное одобрение.`,
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `Сразу после консультации я успокоилась! Я просто выдохнула, земля вернулась под ноги. Мне буквально стало легче. Самое крутое — что ответ я получила сразу же. <span>В прошлом у меня был опыт проживания разрыва с психологом и там я спустила кучу денег и времени на часовые консультации и такой ясности ума не приобрела.</span>`,
        `Спустя время после консультации я проживаю всё то, о чём говорили карты. Меня не выбивает из колеи финансовые затруднения, потому что я знаю, что они временные. Сейчас я уже вижу те новые горизонты, о которых говорили карты и вообще, <span>с каждым днём уверена в своём решении всё больше и больше.</span>`,
      ],
    },
  },
  {
    user: {
      mobileImage: {
        onex: "/main/user2.jpg",
        twox: "/main/user2.jpg",
        threex: "/main/user2.jpg",
      },
      desktopImage: {
        onex: "/main/user2.jpg",
        twox: "/main/user2.jpg",
        threex: "/main/user2.jpg",
      },
      name: "Дмитрий",
      subtitle: "Инструктор \u000Aпо дыханию",
      sessionDuration: 15,
      price: 15 * EXPERT_RATE_FOR_TEXTS,
      result: "Стал внимательнее относиться к\u00a0тратам, продумывает покупки",
    },
    expert: {
      id: 2,
      mobileImage: {
        onex: "/main/expert4",
        twox: "/main/expert4",
        threex: "/main/expert4",
      },
      desktopImage: {
        onex: "/main/expert4",
        twox: "/main/expert4",
        threex: "/main/expert4",
      },
      name: "Ирин Сан",
      isFired: true,
      url: "/expert/2",
    },
    request: {
      title: `Запрос`,
      content: [`Что мне нужно предпринять, чтобы улучшить моё финансовое положение?`],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `С самой первой карты было попадание в самую точку. Она была про мои взаимоотношения с деньгами. Всё совпало. Я подумал, что это просто случайность, но и следующая карта была 100% про меня. Легкое пренебрежение сошло на нет, я задумался. <span>Эксперт рассказала мне, что я воспринимаю деньги как средство для получения удовольствия.</span> Я никак не управляю деньгами и не структурирую их.`,
        `Мне очень понравилась консультация. Современный подход, непринужденная обстановка. Эксперт очень располагала к себе, приводила примеры и я чувствовал, что она старается дать максимальную пользу. Особенно я оценил то, что на расклад ушло буквально 15 минут. Мой предыдущий опыт консультаций был совсем не таким. <span>И это не сессии с психологами, когда для решения одного запроса уходит минимум 5 консультаций.</span> Я точно приду за ещё одним раскладом!`,
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `Сразу после консультации я стал более внимательным. <span>Я задумался о том, насколько мне нужно то, что покупаю.</span> Например, я каждое утро заходил в красивое кафе и покупал там маффин и большое какао. Конечно, я не наедался этим, а тратил ежедневно 500 рублей! И я просто нашел неподалёку более бюджетное кафе.`,
        `Спустя месяц я понимаю, что появилось большее внимание к деньгам. Во мне зародились нотки экономности, я привожу в порядок все свои траты. <span>Пришло понимание, что не обязательно тратить деньги на всё, что приносит мне удовольствие.</span> Ведь его можно получать не только из спонтанных трат. А на сэкономленные деньги можно найти более полезные альтернативы.`,
      ],
    },
  },
  {
    user: {
      mobileImage: {
        onex: "/main/user3.jpg",
        twox: "/main/user3.jpg",
        threex: "/main/user3.jpg",
      },
      desktopImage: {
        onex: "/main/user3.jpg",
        twox: "/main/user3.jpg",
        threex: "/main/user3.jpg",
      },
      name: "Алевтина",
      subtitle: "Менеджер, \u000Aмузыкант",
      sessionDuration: 17,
      price: 17 * EXPERT_RATE_FOR_TEXTS,
      result: "Узнала свои сильные стороны и\u00a0наметила цель развития",
    },
    expert: {
      id: 20,
      mobileImage: {
        onex: "/main/expert5",
        twox: "/main/expert5",
        threex: "/main/expert5",
      },
      desktopImage: {
        onex: "/main/expert5",
        twox: "/main/expert5",
        threex: "/main/expert5",
      },
      name: "Настин",
      isFired: true,
      url: "/expert/20",
    },
    request: {
      title: `Запрос`,
      content: [
        `Я переехала в Петербург, чтобы развиваться в музыке, но встречаю одни препятствия. Может, мне нужно заняться чем-то другим?`,
      ],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `Настин определила мой Аркан личности, который полностью соответствует моему характеру — целеустремленность, активность. <span>Она дала важное направление по моему запросу</span> — определить средства достижения цели и просить помощи у людей, сведущих в музыке.`,
        `Таролог верно подметила, что мой музыкальный путь начался не по моей воле, а по наставлению родителей. Вскоре мои музыкальные способности раскрылись, и я стала получать удовольствие от занятий. На консультации <span>Настин подчеркнула, что я смогу добиться желаемого, приложив усилия, ведь я человек волевой.</span>`,
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `Я удостоверилась, что мне открыты дороги, стоит только сосредоточиться на желаемом, попросить помощи и всё обязательно получится! <span>Я поставила цель и теперь иду к ней, ни в чём не сомневаясь!</span>`,
      ],
    },
  },
];
