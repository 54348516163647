import React, { useContext, useEffect } from "react";

import Condition from "@/components/common/condition/condition";
import PromoBlock from "@/components/common/promoBlock";
import Main from "@/components/main";
import { COMMON_PREVIEW_IMAGE_URL } from "@/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { useBanners } from "@/hooks/useBanners";
import { useMarketingHook } from "@/marketing/marketingHook";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";

import Constructor from "../constructor";

import { InfoPageProps } from "./types";

import "../styles.scss";
import "./styles.scss";

const InfoPage = ({ pageContext, location }: InfoPageProps) => {
  const {
    seoId,
    title = "Информация о Lunaro",
    ogTitle,
    description = "Информация о Lunaro",
    text,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    url,
    zone,
    expertsInitial,
    wideBannerData,
  } = pageContext;

  const { email, isUserLoggedIn } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);
  const { marketingTrackFlocktoryExchange, marketingTrackGet4clickExchange } = useMarketingHook();
  const { checkIsWideBannerActive } = useBanners();

  useEffect(() => {
    if (location.state?.showBannerFromFlocktory && !isExpert && email !== undefined) {
      marketingTrackGet4clickExchange(email || "");
      marketingTrackFlocktoryExchange(email || "xname@flocktory.com");
    }
  }, [email, isExpert, location.state?.showBannerFromFlocktory]);

  const wideBannerBannerIsShownOnMain = checkIsWideBannerActive(
    wideBannerData.wideBannerIsShownOnMain,
    wideBannerData.wideBannerIsShownOnlyForAuthorized,
    wideBannerData.wideBannerShowOnProdFlag,
    wideBannerData.wideBannerIsShownWithFreeSession,
  );
  const wideBannerBannerIsShownOnPage = checkIsWideBannerActive(
    wideBannerData.wideBannerIsShownOnPage,
    wideBannerData.wideBannerIsShownOnlyForAuthorized,
    wideBannerData.wideBannerShowOnProdFlag,
    wideBannerData.wideBannerIsShownWithFreeSession,
  );
  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={COMMON_PREVIEW_IMAGE_URL}
      />
      {url === "/" ? (
        <>
          {wideBannerBannerIsShownOnMain && <PromoBlock wideBannerData={wideBannerData} />}
        </>
      ) : (
        <>
          {wideBannerBannerIsShownOnPage && <PromoBlock wideBannerData={wideBannerData} />}
        </>
      )}
      {url === "/"
        ? <Main />
        : (
          <div className="bg--white">
            <div className="page-width">
              <Breadcrumbs
                seoId={seoId}
                url={url}
                breadcrumbDisplayName={breadcrumbDisplayName}
                breadcrumbs={breadcrumbs}
                urlPrefix={BreadcrumbUrlPrefixEnum.Page}
              />
              <div className="info-page">
                {!!header && <h1 className="info-page__headline">{header}</h1>}
                <div
                  className="seo-typography"
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromDangerousTags(text || ""),
                  }}
                />
              </div>
              <Constructor zone={zone} expertsInitial={expertsInitial} />
            </div>
          </div>
        )}
    </>
  );
};

export default InfoPage;
