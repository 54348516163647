import expert1 from "@images/main/experts/01.jpg";
import expert12x from "@images/main/experts/01@2x.jpg";
import expert13x from "@images/main/experts/01@3x.jpg";
import expert2 from "@images/main/experts/02.jpg";
import expert22x from "@images/main/experts/02@2x.jpg";
import expert23x from "@images/main/experts/02@3x.jpg";
import expert3 from "@images/main/experts/03.jpg";
import expert32x from "@images/main/experts/03@2x.jpg";
import expert33x from "@images/main/experts/03@3x.jpg";
import expert4 from "@images/main/experts/04.jpg";
import expert42x from "@images/main/experts/04@2x.jpg";
import expert43x from "@images/main/experts/04@3x.jpg";
import expert5 from "@images/main/experts/05.jpg";
import expert52x from "@images/main/experts/05@2x.jpg";
import expert53x from "@images/main/experts/05@3x.jpg";
import expert6 from "@images/main/experts/06.jpg";
import expert62x from "@images/main/experts/06@2x.jpg";
import expert63x from "@images/main/experts/06@3x.jpg";
import expert7 from "@images/main/experts/07.jpg";
import expert72x from "@images/main/experts/07@2x.jpg";
import expert73x from "@images/main/experts/07@3x.jpg";
import expert8 from "@images/main/experts/08.jpg";
import expert82x from "@images/main/experts/08@2x.jpg";
import expert83x from "@images/main/experts/08@3x.jpg";
import expert9 from "@images/main/experts/09.jpg";
import expert92x from "@images/main/experts/09@2x.jpg";
import expert93x from "@images/main/experts/09@3x.jpg";
import expert10 from "@images/main/experts/10.jpg";
import expert102x from "@images/main/experts/10@2x.jpg";
import expert103x from "@images/main/experts/10@3x.jpg";
import expert11 from "@images/main/experts/11.jpg";
import expert112x from "@images/main/experts/11@2x.jpg";
import expert113x from "@images/main/experts/11@3x.jpg";
import expert12 from "@images/main/experts/12.jpg";
import expert122x from "@images/main/experts/12@2x.jpg";
import expert123x from "@images/main/experts/12@3x.jpg";
import expert13 from "@images/main/experts/13.jpg";
import expert132x from "@images/main/experts/13@2x.jpg";
import expert133x from "@images/main/experts/13@3x.jpg";
import expert from "@images/main/experts/expert-main-new.jpg";
import expert2x from "@images/main/experts/expert-main-new@2x.jpg";
import expert3x from "@images/main/experts/expert-main-new@3x.jpg";

import { BulletBlockItem } from "@/components/common/bulletBlock/types";
import { CarouselItemType, ExpertShowcaseImageType, ShowcaseExpertType } from "@/components/common/showcaseBlock/types";
import { EXPERT_RATE_FOR_TEXTS } from "@/constants";

const isFFAllowFirstVideoSessionWithoutPaymentOn = process.env.GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT === "true";

export const PRIVILEGES: BulletBlockItem[] = [
  {
    id: "1",
    title: "Проверенные тарологи",
    description: "Рекомендуем экспертов,\u00a0к которым обратились бы сами",
  },
  {
    id: "2",
    title: "Безопасная оплата",
    description: "Только через сервис, без прямых переводов на карту экспертам",
  },
  {
    id: "3",
    title: "Конфиденциальность",
    description: "Мы не записываем сессии и даём возможность позвонить анонимно",
  },
  {
    id: "4",
    title: "Возврат денег",
    description: "Вернём деньги, если возникнут проблемы",
  },
];

export const MAIN_PAGE_SHOWCASE_CAROUSEL_ITEMS: CarouselItemType[] = [
  {
    id: "1",
    content: "Что меня ждёт в будущем?",
  },
  {
    id: "2",
    content: "Будем ли мы вместе?",
  },
  {
    id: "3",
    content: "Нужно ли менять работу?",
  },
  {
    id: "4",
    content: "Когда я встречу любовь?",
  },
];

export const MAIN_PAGE_SHOWCASE_LABELS = [
  {
    id: "1",
    content: "Консультация от 7 мин",
  },
  {
    id: "2",
    content: `Цена от\u00a0${EXPERT_RATE_FOR_TEXTS}\u00a0₽/мин`,
  },
];

export const MAIN_PAGE_SHOWCASE_SLANTED_LABELS = [
  {
    id: "1",
    content: isFFAllowFirstVideoSessionWithoutPaymentOn
      ? "1-я консультация"
      : "Первые 7 минут",
  },
  {
    id: "2",
    content: isFFAllowFirstVideoSessionWithoutPaymentOn
      ? "бесплатная"
      : "в подарок",
  },
];

export const MAIN_PAGE_SHOWCASE_EXPERT_DATA: ShowcaseExpertType[] = [{
  expertName: "София Таирова",
  linkUrl: `/expert/118`,
},
{
  expertName: "Ирин Сан",
  linkUrl: `/expert/2`,
},
{
  expertName: "Лита Вэйн",
  linkUrl: `/expert/7`,
},
{
  expertName: "Анна Лесная",
  linkUrl: `/expert/105`,
},
{
  expertName: "Галина",
  linkUrl: `/expert/169`,
},

{
  expertName: "Екатерина Каменева",
  linkUrl: `/expert/166`,
},
{
  expertName: "Марс Вибран",
  linkUrl: `/expert/168`,
},
{
  expertName: "Саша Шу",
  linkUrl: `/expert/170`,
},
{
  expertName: "Михаил Пересторонин",
  linkUrl: `/expert/160`,
},
{
  expertName: "Лилия Батурина",
  linkUrl: `/expert/35`,
},
{
  expertName: "Екатерина Филей",
  linkUrl: `/expert/167`,
},
{
  expertName: "Диана Лурье",
  linkUrl: `/expert/121`,
},
{
  expertName: "Ружена",
  linkUrl: `/expert/29`,
},
];

export const MAIN_PAGE_SHOWCASE_IMAGE_DATA: ExpertShowcaseImageType[] = [{
  oneX: expert,
  twoX: expert2x,
  threeX: expert3x,
}, {
  oneX: expert1,
  twoX: expert12x,
  threeX: expert13x,
}, {
  oneX: expert2,
  twoX: expert22x,
  threeX: expert23x,
}, {
  oneX: expert3,
  twoX: expert32x,
  threeX: expert33x,
}, {
  oneX: expert4,
  twoX: expert42x,
  threeX: expert43x,
}, {
  oneX: expert6,
  twoX: expert62x,
  threeX: expert63x,
}, {
  oneX: expert7,
  twoX: expert72x,
  threeX: expert73x,
}, {
  oneX: expert8,
  twoX: expert82x,
  threeX: expert83x,
}, {
  oneX: expert9,
  twoX: expert92x,
  threeX: expert93x,
}, {
  oneX: expert10,
  twoX: expert102x,
  threeX: expert103x,
}, {
  oneX: expert11,
  twoX: expert112x,
  threeX: expert113x,
}, {
  oneX: expert12,
  twoX: expert122x,
  threeX: expert123x,
}, {
  oneX: expert13,
  twoX: expert132x,
  threeX: expert133x,
}];
