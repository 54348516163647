import * as React from "react";

import checkGreen from "@images/checkGreen.svg";
import arrowLong from "@images/main/arrow-long.svg";

import "./styles.scss";

const ExpertSelection = () =>
  (
    <div className="main-page__item expert-selection">
      <div className="page-width expert-selection__inner">
        <div className="expert-selection__container">
          <div className="expert-selection__image">
            <img
              src="/main/phone.jpg"
              srcSet="/main/serafi.jpg, /main/serafi@2x.jpg 2x"
              alt=""
              className="expert-selection__image-expert"
              loading="lazy"
              width="402"
              height="532"
            />
          </div>
          <div className="expert-selection__info">
            <h2 className="h1 main-page__title --left expert-selection__title">
              Строгий отбор экспертов
            </h2>
            <p>
              Каждый день мы получаем десятки сообщений от эзотериков, которые хотят работать на
              Lunaro. Мы строго оцениваем всех соискателей. И
              {" "}
              <b>только 1 из 20</b>
              {" "}
              доходит до
              собеседования.
            </p>
            <p>
              Все эксперты проходят
              {" "}
              <b>трехэтапный отбор</b>
              , прежде чем попасть на Lunaro
            </p>
          </div>
          <div className="expert-selection__steps">
            <div className="expert-selection__step">
              <img src={checkGreen} alt="" className="expert-selection__step-icon" />
              <img src={arrowLong} alt="" className="expert-selection__step-arrow" />
              <div className="h3 expert-selection__step-title">
                <span>Интервью</span>
              </div>
              <div className="expert-selection__step-text">
                Отслеживаем коммуникативные навыки, манеру и стиль речи
              </div>
            </div>
            <div className="expert-selection__step">
              <img src={checkGreen} alt="" className="expert-selection__step-icon" />
              <img src={arrowLong} alt="" className="expert-selection__step-arrow" />
              <div className="h3 expert-selection__step-title">
                <span>Тестирование</span>
              </div>
              <div className="expert-selection__step-text">
                Проверяем образование таролога и&nbsp;смотрим, как он делает расклады
              </div>
            </div>
            <div className="expert-selection__step">
              <img src={checkGreen} alt="" className="expert-selection__step-icon" />
              <div className="h3 expert-selection__step-title">
                <span>Финальная</span>
                <span>консультация</span>
              </div>
              <div className="expert-selection__step-text">
                Просим провести консультацию независимому лицу и&nbsp;принимаем решение
              </div>
            </div>
          </div>
          <div className="expert-selection__after-text">
            <p>
              Каждый эксперт проходит
              {" "}
              <b>обучение стандартам Lunaro</b>
              , чтобы консультация была комфортной и&nbsp;полезной для вас. А&nbsp;после мы
              постоянно отслеживаем качество работы эксперта по отзывам наших клиентов
            </p>
          </div>
        </div>
      </div>
    </div>
  );

export default ExpertSelection;
