import React from "react";

import { Helmet } from "react-helmet";

import Accordion from "@/components/common/accordion";
import { isEven, isOdd } from "@/utils/numberUtils";

import { ACCORDION_DATA } from "./constants";
import "./styles.scss";

const FAQ = () =>
  (
    <>
      <Helmet>
        <html lang="ru" itemScope itemType="https://schema.org/FAQPage">
          {JSON.stringify(ACCORDION_DATA)}
        </html>
      </Helmet>
      <div className="main-page__item --small-indent faq">
        <div className="page-width">
          <div className="faq__container">
            <h2 className="h1 main-page__title faq__title">Частые вопросы</h2>
            <div className="faq__columns">
              <div className="faq__list">
                {ACCORDION_DATA.mainEntity.map(
                  (item, index) =>
                    isEven(index) && (
                    <Accordion
                      key={index}
                      className="faq__item"
                      summary={item.name}
                      details={item.answer.text}
                    />
                    ),
                )}
              </div>
              <div className="faq__list">
                {ACCORDION_DATA.mainEntity.map(
                  (item, index) =>
                    isOdd(index) && (
                    <Accordion
                      key={index}
                      className="faq__item"
                      summary={item.name}
                      details={item.answer.text}
                    />
                    ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

export default FAQ;
