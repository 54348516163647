import React from "react";

import { Icon, IconSizeEnum } from "@components/common/icon";

type IProps = {
  link: any;
  icon: string;
  title: any;
  color?: string;
};

const ChipButton = ({
  link, icon, title, color,
}: IProps) =>
  (
    <a href={link} target="_blank" rel="noreferrer">
      <div className="chip request__socials--chip">
        <span className={`chip__icon chip__icon--request ${color || ""}`}>
          <Icon type={icon} size={IconSizeEnum.Size24} />
        </span>
        <span className="chip__text chip__text--request">{title}</span>
      </div>
    </a>
  );

export default ChipButton;
