import React from "react";

import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";

import { ExpertState, OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";
import {
  getExperts as getExpertsType,
  getExpertsVariables,
  getExperts_getExperts_edges,
} from "@/components/catalog/graphql/__generated__/getExperts";
import { GET_EXPERTS } from "@/components/catalog/graphql/GET_EXPERTS";
import { Button, ButtonColorEnum, ButtonIconPositionEnum } from "@/components/common/button";
import ExpertCard from "@/components/common/expertCard";
import { IconTypeEnum } from "@/components/common/icon";

import "./styles.scss";

const ExpertList = () => {
  const { data } = useQuery<getExpertsType, getExpertsVariables>(GET_EXPERTS, {
    variables: {
      sorting: {
        order: OrderEnum.desc,
        sortBy: SortByEnum.rating,
      },
    },
    fetchPolicy: "network-only",
  });

  const sortExpertsByAvailability = (experts: (getExperts_getExperts_edges | null)[]) => {
    const sortedExperts = [...experts];
    sortedExperts.sort((a, b) => {
      if (a?.node?.state === ExpertState.available && b?.node?.state !== ExpertState.available) {
        return -1;
      }
      if (a?.node?.state !== ExpertState.available && b?.node?.state === ExpertState.available) {
        return 1;
      }
      return 0;
    });
    return sortedExperts;
  };

  return (
    <div className="page-width">
      <div className="expert-List-container">
        <div className="expert-List-container__title-container">
          <span className="expert-List-container__title">Готовы попробовать?</span>
          <span className="expert-List-container__description">Эксперты Lunaro ждут вас</span>
        </div>
        <hr />
        {data?.getExperts?.edges
          && sortExpertsByAvailability(data?.getExperts?.edges)
            .slice(0, 3)
            .map((expertItem) =>
              (expertItem?.node ? (
                <div className="expert-List-container__expert-item-block" key={expertItem.node.id}>
                  <ExpertCard expert={expertItem.node} />
                  <hr />
                </div>
              ) : null))}
        <div className="expert-List-container__button-block">
          <Button
            text="Посмотреть всех экспертов"
            icon={IconTypeEnum.Right}
            iconPosition={ButtonIconPositionEnum.Right}
            color={ButtonColorEnum.Black}
            onClick={() =>
              navigate("/catalog")}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpertList;
